export default function messages(state = {}, action) {
  let returnObj; 
  switch (action.type) {
    case 'LOGIN_FAILURE':
    case 'UPDATE_PROFILE_FAILURE':
    case 'CHANGE_PASSWORD_FAILURE':
    case 'FORGOT_PASSWORD_FAILURE':
    case 'RESET_PASSWORD_FAILURE':
    case 'FAILURE':
      if(window)window.scrollTo(0,0);

      returnObj =  {
        error: action.messages
      };
      break;
    case 'UPDATE_PROFILE_SUCCESS':
    case 'CHANGE_PASSWORD_SUCCESS':
    case 'RESET_PASSWORD_SUCCESS':
    case 'CONTACT_FORM_SUCCESS':
      case 'SUCCESS':
        if(window)window.scrollTo(0,0);
        returnObj =  {
        success: action.messages
      };
      break;

    case 'FORGOT_PASSWORD_SUCCESS':
    case 'DELETE_ACCOUNT_SUCCESS':
    case 'UNLINK_SUCCESS':
      if(window)window.scrollTo(0,0);
      returnObj =  {
        info: action.messages
      };
      break;

    case 'CLEAR_MESSAGES':
      returnObj = {};
      break;

    default:
      returnObj =  state;
  }
  return returnObj;
}
