import React from 'react';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import {signup} from '../../actions/auth';
// import { facebookLogin, twitterLogin, googleLogin, vkLogin, githubLogin } from '../../actions/oauth';
import Messages from '../../components/Messages';

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email : '',
      password: '',
      loginAs :''
    }

    // this.state = {email: 'kate@bermacsafety.com', password: '5ms924142'};
    // this.state = {email: 'admin@test.com', password: '5ms924142'};
    // this.state = { loginAs:"admin"};
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleLogin(event) {
    let {
        email,
        account_name,
        first_name,
        last_name,
        phone,
        password,
        re_password
    } = this.state;

    if( password != re_password )
    {
        alert("Password and confirm password do not match.");
    }
    event.preventDefault();
    this.props.dispatch(signup({
        email,
        account_name,
        first_name,
        last_name,
        phone,
        password,
        re_password
    }));
  }
componentDidMount(){
 
}
  render() {
    return (
      <div className="container-fluid dashboard-content">
                  <div className="row">
                    <div className="col-md-4 offset-md-4">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="page-header">
                                <h2 className="pageheader-title">Register </h2>
                            </div>
                        </div>
                    </div>
            <Messages messages={this.props.messages} />
            <form onSubmit={this.handleLogin.bind(this)}>
              {/* <legend>Log In</legend> */}
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  required
                  autoFocus
                  className="form-control"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                />
              </div>


              <div className="form-group">
                <label htmlFor="email">Account Name</label>
                <input
                  type="account_name"
                  name="account_name"
                  required
                  id="account_name"
                  placeholder="Account Name"
                   
                  className="form-control"
                  value={this.state.account_name}
                  onChange={this.handleChange.bind(this)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="first_name">First Name</label>
                <input
                  type="first_name"
                  name="first_name"
                  required
                  id="first_name"
                  placeholder="First Name"
                   
                  className="form-control"
                  value={this.state.first_name}
                  onChange={this.handleChange.bind(this)}
                />
              </div>

              
              <div className="form-group">
                <label htmlFor="last_name">Last Name</label>
                <input
                  type="last_name"
                  name="last_name"
                  id="last_name"
                  placeholder="Last Name"
                  required
                   
                  className="form-control"
                  value={this.state.last_name}
                  onChange={this.handleChange.bind(this)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input
                  type="phone"
                  name="phone"
                  id="phone"
                  placeholder="Phone Number"
                  required
                   
                  className="form-control"
                  value={this.state.phone}
                  onChange={this.handleChange.bind(this)}
                />
              </div>


              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  required
                  placeholder="Password"
                  className="form-control"
                  value={this.state.password}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="re_password">Confirm Password</label>
                <input
                  type="password"
                  name="re_password"
                  required
                  id="re_password"
                  placeholder="Confirm Password"
                  className="form-control"
                  value={this.state.re_password}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
            <div class="form-group ">
                <div class="col-xs-12">
                    <label class="cr-styled">
                        <input type="checkbox" checked required name="terms" />
                        <i class="fa"></i>
                         I accept <strong><a href="https://bermacsafety.app/privacy.html" target="_blank">Terms and Conditions</a></strong>
                    </label>
                </div>
            </div>
            
              <button type="submit" className="btn btn-primary">
              Register
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    messages: state.messages
  };
};

export default connect(mapStateToProps)(Signup);
