import React from 'react';
// import { IndexLink, Link } from "react-router";
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
// import { logout } from "../actions/auth";
// import moment from "moment";

class Header extends React.Component {
  state = {links: []};

  componentDidMount() {
    let links = [];
    if (!this.props.user) return;

    if (this.props.user.role === 'team') {
      links.push({
        is_title: true,
        name:"Configuration",
        noMt: true,
      });
      links.push({
        icon: 'fa fa-chart-bar',
        name: 'Account profile',
        path: '/my-profile',
      });
      links.push({
        icon: 'fa fa-chart-bar',
        name: 'App Dashboard Settings',
        path: '/app_main_page',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'Users',
        path: '/users',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'Add New user',
        path: '/add-user',
      });
      links.push({
        is_title: true,
        name:"Customization"
      });

      links.push({
        name:"OSHA On-Site",
        path: '/button_press_settings/1'
      });
      links.push({
        name:"Report an Incident/Accident",
        path: '/button_press_settings/2'
      });
      links.push({
        name:"Report a Safety Complaint",
        path: '/button_press_settings/4'
      });
      links.push({
        name:"Lone Worker Emergency",
        path: '/button_press_settings/5'
      });
      // links.push({
      //   name:"Lone Worker Emergency",
      //   path: '/button_press_settings/5'
      // });
      links.push({
        name:"Ask a Safety Question",
        path: '/form_settings/1'
      });
      links.push({
        name:"Need a Safety Product",
        path: '/form_settings/3'
      });
      links.push({
        name:"Request Safety Consultation",
        path: '/form_settings/2'
      });
      
      links.push({
        is_title: true,
        name:"Safety Person"
      });

      links.push({
        name:"Safety Contacts For App Users",
        path: '/safety-contacts'
      });
      links.push({
        is_title: true,
        name:"Notification Records"
      });


      links.push({
        name:"OSHA On-Site",
        path: '/button_data/1'
      });
      links.push({
        name:"Report an Incident/Accident",
        path: '/button_data/2'
      });
      links.push({
        name:"Report a Safety Complaint",
        path: '/button_data/4'
      });
      links.push({
        name:"Lone Worker Emergency",
        path: '/button_data/5'
      });


      links.push({
        name:"Ask a Safety Question",
        path: '/form_data/1'
      });
      links.push({
        name:"Need a Safety Product",
        path: '/form_data/3'
      });
      links.push({
        name:"Request Safety Consultation",
        path: '/form_data/2'
      });


      

    } else if (this.props.user.role === 'admin') {
      links.push({
        icon: 'fa fa-chart-bar',
        name: 'Subscribers',
        path: '/subscribers',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'Configuration',
        path: '/configuration',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'Safety Contacts',
        path: '/SafetyContacts',
      });
      links.push({
        icon: 'fa fa-user',
        name: 'Plan Types',
        path: '/plans',
      });
    }
    this.setState({
      links: links,
    });
  }
  render() {
    const active = {borderBottomColor: '#3f51b5'};
    return (
      <nav className="navbar navbar-expand-lg  navbar-light flex-md-column flex-row align-items-stretch">
        <button
          className="navbar-toggler order-md-1 order-2"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          onClick={()=>{
            this.setState({showingMenu : !this.state.showingMenu})
          }}
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          style={{
            textAlign: 'center',
            background: '#fff',
            minHeight: 75,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <img
            src={this.props.user.role == 'team' ? '/images/logo.png' : "/assets/images/logo.png"}
            style={{maxWidth: 100, objectFit: 'contain', width: 85}}
            alt="logo"
            className="img-flui mt-0 order-1 order-md-1"
          />
        </div>

        <div
          className={"collapse navbar-collapse pt-3 mt-3 order-md-3 order-3"+(this.state.showingMenu?  ' show ': ' ')}
          id="navbarNav">
          {this.props.token ? (
            <ul className="navbar-nav flex-column ">
              {this.state.links.map((item, i) => {
                return (
                  <li className="nav-item" key={item.name+ i}>
                    {item.is_title ? (
                      <span className="nav-link"
                        style={{
                          marginTop: item.noMt ? 0 : '30px',
                          display: 'inline-block',
                          // padding: '12px',
                          color: '#4fc04d',
                          letterSpacing: '1px',
                        }}>
                        {item.name}
                      </span>
                    ) : (
                      <Link className="nav-link" to={item.path} onClick={()=>{
      if(window)window.scrollTo(0,0);
                        
                          this.props.dispatch({
                            type: 'CLEAR_MESSAGES',
                          });
                      }}>
                        {/* {item.icon ? (
                          <span className={item.icon}></span>
                        ) : (
                          false
                        )}{' '} */}
                        {item.name}
                      </Link>
                    )}
                    {/* {item.items ? (
                      <div id={item.id} className="collapse submenu">
                        <ul className="nav flex-column">
                          {item.items.map((sub, i) => {
                            if (sub.is_label) {
                              return (
                                <li key={sub.name}>&nbsp;&nbsp;{sub.name}</li>
                              );
                            }
                            return (
                              <li key={sub.name} className="nav-item">
                                <Link
                                  className="nav-link"
                                  to={sub.is_force ? '/downloading' : sub.path}
                                  onClick={() => {
                                    if (sub.is_force) {
                                      window.location.href = sub.path;
                                      return false;
                                    }
                                  }}
                                  activeStyle={active}>
                                  {sub.name}{' '}
                                  {sub.is_new ? (
                                    <span className="badge badge-danger">
                                      New
                                    </span>
                                  ) : (
                                    false
                                  )}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      false
                    )} */}
                  </li>
                );
              })}
              <li  className="nav-item" >
                <span className='nav-link'></span>
              </li>
              <li  className="nav-item" >
                <span className='nav-link'></span>
              </li>
              <li  className="nav-item" >
                <span className='nav-link'></span>
              </li>
              <li  className="nav-item" >
                <span className='nav-link'></span>
              </li>
            </ul>
          ) : (
            false
          )}
          {/* {rightNav} */}
        </div>
        {/* </div> */}
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
  };
};

export default connect(mapStateToProps)(Header);
