import common from '../components/common';
import history from '../history';


export function login(email, password, role) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch(common.bs+'/login', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        email: email,
        password: password,
        role : role
      })
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {

          if(!json.ok)
          {
            dispatch({
              type: 'LOGIN_FAILURE',
              messages: Array.isArray(json) ? json : [json]
            });
            return;
          }
          // console.log(json);return;
          dispatch({
            type: 'LOGIN_SUCCESS',
            token: json.data.token,
            role :role,
            user: json.data.user
          });
          window.localStorage.setItem("userInfo", JSON.stringify(json.data.user));
          window.localStorage.setItem("userRole", json.data.role);
          window.localStorage.setItem("userToken", json.data.token);

          if(json.data.user.role==='team')
          {
            // history.push('/companies');
            window.location.href="/app/app_main_page";
          }else if(json.data.user.role==='admin')
          {
            // history.push('/companies');
            window.location.href="/app/subscribers";
          }
          
        });
      } else {
        return response.json().then(json => {
          dispatch({
            type: 'LOGIN_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}

export function signup(data ) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch(common.bs+'/signup', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data)
    }).then(response => {
      return response.json().then(json => {

        if(!json.ok)
        {
          dispatch({
            type: 'LOGIN_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
          return;
        }

        dispatch({
          type: 'SUCCESS',
          messages:  [{ok: true, msg: 'Regsitration Successful. Please login'}]
        });
         
          setTimeout(()=>{
            window.location.href='/app/'
          }, 3000);
          // Cookies.setCookie('token', json.token, {
          //   expires: moment()
          //     .add(1, 'month')
          //     .toDate()
          // });
        
      });
    });
  };
}

export function logout() {
  // Cookies.removeCookie('token');
  localStorage.removeItem('userInfo')
  localStorage.removeItem('userRole')
  localStorage.removeItem('userToken')
  // history.push('/');
  window.location.href = '/';
  return {
    type: 'LOGOUT_SUCCESS'
  };
}

export function forgotPassword(email, cb) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch('/forgot', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({email: email})
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {
          cb && cb();
          dispatch({
            type: 'FORGOT_PASSWORD_SUCCESS',
            messages: [json]
          });
        });
      } else {
        return response.json().then(json => {
          cb && cb();
          dispatch({
            type: 'FORGOT_PASSWORD_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}

export function resetPassword(password, confirm, pathToken) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch(`/reset/${pathToken}`, {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        password: password,
        confirm: confirm
      })
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {
          history.push('/login');
          dispatch({
            type: 'RESET_PASSWORD_SUCCESS',
            messages: [json]
          });
        });
      } else {
        return response.json().then(json => {
          dispatch({
            type: 'RESET_PASSWORD_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}

export function updateProfile(state, token) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch('/api/auth/updateUser', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({data: {
        // email: state.email,
        phonenumber: state.phonenumber,
        first_name: state.first_name,
        middle_name: state.middle_name,
        last_name: state.last_name,
        // gender: state.gender,
        // location: state.location,
        // website: state.website
      }})
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {
          // alert('dispatching')
          dispatch({
            type: 'UPDATE_USER_INFO',
            data : {
              phonenumber: state.phonenumber,
              first_name: state.first_name,
              middle_name: state.middle_name,
              last_name: state.last_name,
            }
          })
          dispatch({
            type: 'UPDATE_PROFILE_SUCCESS',
            messages: [json]
          });
        });
      } else {
        return response.json().then(json => {
          dispatch({
            type: 'UPDATE_PROFILE_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}

export function changePassword(password, confirm, token) {
  return dispatch => {
    dispatch({
      type: 'CLEAR_MESSAGES'
    });
    return fetch('/api/auth/updateUser', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({data:{
        password: password,
      }
        // confirm: confirm
      })
    }).then(response => {
      if (response.ok) {
        return response.json().then(json => {
          dispatch({
            type: 'CHANGE_PASSWORD_SUCCESS',
            messages: [json]
          });
        });
      } else {
        return response.json().then(json => {
          dispatch({
            type: 'CHANGE_PASSWORD_FAILURE',
            messages: Array.isArray(json) ? json : [json]
          });
        });
      }
    });
  };
}
