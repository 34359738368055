import React from 'react';
import {connect} from 'react-redux';
import Fetcher from '../../actions/Fetcher';
import moment from 'moment';
import {Link} from 'react-router-dom'
import Messages from '../../components/Messages'; 

import common from '../../components/common';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { useParams } from "react-router-dom";
// import moment from 'moment';
let btnlabels = {
  'f_1' : 'OSHA On-Site',
  'f_2' : 'Report an Incident/Accident',
  'f_4'    :  'Report a Safety Complaint',
  'f_5'    :  'Lone Worker Emergency',
}
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class ButtonData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      page: 1,
      perPage: 20,
      data: [],
      loading: true,
      pagination: {},
    };
  }

  columns = [
    {name: 'Submission Id', sortable: true, width: '140px', selector: row => row.id, sortField:'id'},
    {
      sortable: true,width: '230px',
      name: 'Date and Time of Notification', sortField:'created_at',
      selector: row=>row.created_at,
      format: row => moment(row.created_at).format('lll'),
    },
    {
      sortable:false, grow:1,name:"Location", selector: row=>row.location, format: row=>(
      <a className="text-primary" href={'https://www.google.com/maps/place/'+row.location}  rel="noreferrer" target="_blank">{row.location}</a>
      )
    },
    {name: 'name', selector: row => row.app_user? row.app_user.name :'', sortable: false },
    {name: 'Email', selector: row =>  row.app_user? row.app_user.email :'', sortable: false },
    {name: 'Phone', selector: row =>  row.app_user? row.app_user.phone :'', sortable: false },
    {
      sortable:false, name:'Actions',
      cell: u => (
        <button className={'badge badge-danger'}  onClick={()=>{
          if(this.state['update_' + u.id])return;
            if(window.confirm('Are you sure you want to delete this record?'))
            {
              this.setState({['update_' + u.id]: true})

              this.deleteItem(u.id);
            }
        }}>Delete Item</button>
      )

    },
  
    
    
  ];
 
  async componentDidMount() {
    this.handlePageChange(this.state.page);
  }

  handleSort = async (a, b) => {
    this.setState(
      {
        sort: a.sortField,
        sort_order: b,
      },
      () => {
        this.handlePageChange(1);
      }
    );
  };

  handleOnChangeRowsPerPage = async page => {
    this.setState(
      {
        perPage: page,
      },
      () => {
        this.handlePageChange(1);
      },
    );
  };

  handlePageChange = async page => {
    const {perPage} = this.state;
    const planId = this.props.params.teamId;
    const formId = this.props.params.btnId;
    this.setState({loading: true});
    let sort = '';
    if (this.state.sort) {
      sort = "&sort=" + this.state.sort + "&order=" + this.state.sort_order;
    }
    const response = await axios.get(
      `${common.bs}/listPaged/button_click?related=app_user&filter_button_id=${formId}&page=${page}&per_page=${perPage}&delay=1${sort}&token=${this.props.token}&filter_team_id=${planId}`,
    );
    console.log(response);
    this.setState({
      items: response.data.items,
      page: page,
      totalRows:
        response.data.pagination.pageCount * response.data.pagination.pageSize,
      loading: false,
    });
  };

  
  deleteItem(id) {
    Fetcher.post(common.bs + `/delete/button_click?token=${this.props.token}`, {
      id: id,
    })
      .then(resp => {
        this.setState({
          ['delete_' + id]: false,
        });
        if (resp.ok) {
          this.props.dispatch({
            type: 'SUCCESS',
            messages: [{ok: true, msg: 'Deleted Successfully.'}],
          });
          setTimeout(()=>{
            this.props.dispatch({
              type: 'CLEAR_MESSAGES',
            });
          }, 2000);
          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({type: 'FAILURE', messages: [resp]});
        }
      })
      .catch(err => {
        console.log(err);
        const msg = 'Failed to perform Action';
        this.props.dispatch({
          type: 'FAILURE',
          messages: [{msg: msg}],
        });
        setTimeout(()=>{
          this.props.dispatch({
            type: 'CLEAR_MESSAGES',
          });
        }, 2000);
      });
  }


  render() {
    const {totalRows, loading} = this.state;
    
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <Messages messages={this.props.messages} />
            <DataTable
              title={'Submissions for   "' + btnlabels['f_'+this.props.params.btnId] + '"    button'}
              columns={this.columns}
              data={this.state.items}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 75, 100, 200, 500]}
              highlightOnHover
              fixedHeader
              paginationPerPage={this.state.perPage}
              noContextMenu
              fixedHeaderScrollHeight={'70vh'}
              pagination
              progressPending={loading}
              persistTableHead
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleOnChangeRowsPerPage}
              onSort={this.handleSort}
              sortServer
              actions={[
                <Link
                className="btn btn-dark btn-rounded"
                to={"/subscriber/data/"+this.props.params.teamId}
                key="subscribers">
                <i className="fa-solid fa-chevron-left"></i> Go Back
              </Link> 
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(withParams(ButtonData));
