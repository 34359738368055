import {Component} from 'react';
import {connect} from 'react-redux';
import common from '../../components/common';
import Messages from '../../components/Messages';
import axios from 'axios';
import Fetcher from '../../actions/Fetcher';
import {useParams} from 'react-router-dom';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class FormSettings extends Component {
  state = {
    alert_on_user_screen: '',
    mail_to_admin_emails: '',
    mail_to_admin_subject: '',
    mail_to_admin_message: '',
    mail_to_user: '',
    mail_to_user_subject: '',
    mail_to_user_message: '',
    mail_to_bermac: '',
    mail_to_bermac_subject: '',
    mail_to_bermac_message: '',
  };
  formNames = {
    f_1 : "Ask a Safety Question",
    f_3 : "Need a Safety Product",
    f_2 : "Request Safety Consultation",
  };

  vals = [
    'mail_to_admin_emails',
    'mail_to_admin_subject',
    'mail_to_admin_message',
    'mail_to_user',
    'mail_to_user_subject',
    'mail_to_user_message',
    'mail_to_bermac',
    'mail_to_bermac_subject',
    'mail_to_bermac_message',
    'alert_on_user_screen',
  ];
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params.formId !== this.props.params.formId) {
      this.fetchData();
      // console.log('something prop has changed.')
    }
  }
  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }
  async componentDidMount() {
    this.fetchData();
  }
  async fetchData() {
    let formId = this.props.params.formId;
    let vals = this.vals.join('|');
    vals = vals.split('|').map(function (item, i) {
      return item + '_f_' + formId;
    });
    vals = vals.join('|');
    let url = `${common.teambs}/listPaged/settings?my=yes&filterin_setting_key=${vals}&token=${this.props.token}`;

    const response = await axios.get(url);

    let data = response.data;
    // if (data.ok) {
    this.processInput(data.items || []);
    // }
  }

  processInput(data) {
    let formId = this.props.params.formId;
    let values = {
      alert_on_user_screen: '',
      mail_to_admin_emails: '',
      mail_to_admin_subject: '',
      mail_to_admin_message: '',
      mail_to_user: '',
      mail_to_user_subject: '',
      mail_to_user_message: '',
      mail_to_bermac: '',
      mail_to_bermac_subject: '',
      mail_to_bermac_message: '',

      id_for_alert_on_user_screen: '',
      id_for_mail_to_admin_emails: '',
      id_for_mail_to_admin_subject: '',
      id_for_mail_to_admin_message: '',
      id_for_mail_to_user: '',
      id_for_mail_to_user_subject: '',
      id_for_mail_to_user_message: '',
      id_for_mail_to_bermac: '',
      id_for_mail_to_bermac_subject: '',
      id_for_mail_to_bermac_message: '',
    };
    data.map((item, i) => {
      values[item.setting_key.replace('_f_' + formId, '')] = item.setting_value;
      values['id_for_' + item.setting_key.replace('_f_' + formId, '')] =
        item.id;
      return item;
    });
    console.log(values);
    this.setState(values);
  }

  doneSaving(ch, s, f) {
    let msg = 'Updated Successfully.';
    if (ch) {
      msg = '' + s + ' values updated successfully. ' + f + ' values failed';
    }
    this.props.dispatch({
      type: 'SUCCESS',
      messages: [{ok: true, msg: msg}],
    });
    setTimeout(() => {
      this.props.dispatch({
        type: 'CLEAR_MESSAGES',
      });
    }, 2000);
  }

  async saveItem(id, key, value, cb) {
    // let formId = this.props.params.formId;

    const response = await Fetcher.post(
      common.teambs + '/update/settings?token=' + this.props.token,
      {
        id: id,
        data: {
          setting_value: value,
        },
      },
    );
    // return
    cb(response.ok ? 'yes' : 'no');
  }

  async doSubmit(vals) {
    let needs_saving = vals.length;
    let total_success = 0;
    let total_fail = 0;
    vals.map((item, i) => {
      this.saveItem(
        this.state['id_for_' + item],
        item,
        this.state[item],
        vl => {
          // console.log(vl);

          if (vl === 'yes') {
            total_success = total_success + 1;
          } else {
            total_fail = total_fail + 1;
          }
          needs_saving = needs_saving - 1;

          if (needs_saving < 1) {
            if (total_fail > 0) {
              this.doneSaving(true, total_success, total_fail);
            } else {
              this.doneSaving(false);
            }
          }
        },
      );
      return item;
    });
  }
  doSubmit_1(e) {
    e.preventDefault();
    let keys = ['alert_on_user_screen'];
    this.doSubmit(keys);
  }
  doSubmit_2(e) {
    e.preventDefault();
    let keys = [
      'mail_to_admin_emails',
      'mail_to_admin_subject',
      'mail_to_admin_message',
      'mail_to_user',
      'mail_to_user_subject',
      'mail_to_user_message',
      'mail_to_bermac',
      'mail_to_bermac_subject',
      'mail_to_bermac_message',
    ];
    this.doSubmit(keys);
  }

  render() {
    let formId = this.props.params.formId;
    let formName = this.formNames['f_' + formId];
    let {
      alert_on_user_screen,
      mail_to_admin_emails,
      mail_to_admin_subject,
      mail_to_admin_message,
      mail_to_user,
      mail_to_user_subject,
      mail_to_user_message,
      mail_to_bermac_subject,
      mail_to_bermac_message,
      mail_to_bermac,
    } = this.state;

    let extra_vars = '';
    if ('' + formId === '2' || '' + formId === '3') {
      extra_vars = (
        <>
          , <code>{'{{PRODUCTS}}'}</code>
        </>
      );
    }
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-description">{formName}</h4>
              </div>
            </div>
            <Messages messages={this.props.messages} />
            <br />

            <div className="row">
              <div className="col-md-12 grid-margin">
                <div className="card collapser">
                  <button className="card-header">IN-APP RESPONSE</button>
                  <div className="card-body">
                    <div className="card-description ">
                      <form
                        className="forms-sample"
                        onSubmit={this.doSubmit_1.bind(this)}
                        method="post">
                        <div className="form-group">
                          <label htmlFor="alert_on_user_screen">
                            In-app Alert on screen for user
                          </label>
                          <input
                            className="form-control"
                            name="alert_on_user_screen"
                            id="alert_on_user_screen"
                            onChange={this.handleChange.bind(this)}

                            required
                            value={alert_on_user_screen}
                          />
                        </div>
                        <button
                          type="submit"
                          className="btn btn-dark btn-rounded mr-2"
                          name="msg_to_show_on_screen_saver">
                          Save
                        </button>
                        <button className="btn btn-secondary btn-rounded" type="reset">
                          Reset
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 grid-margin">
                <div className="card collapser">
                  <button className="card-header">
                    Email Notification Settings
                  </button>
                  <div className="card-body hidden">
                    <div className="card-description ">
                      <form
                        className="forms-sample"
                        onSubmit={this.doSubmit_2.bind(this)}
                        method="post">
                        <div className="is_a_set">
                          <div className="form-group">
                            <label htmlFor="mail_to_admin_emails">
                              Email Address(es) of Safety Team(
                              <code>{'Comma Separated'}</code>)
                            </label>
                            <input
                              className="form-control"
                              value={mail_to_admin_emails}
                              name="mail_to_admin_emails"
                              id="mail_to_admin_emails"
                            onChange={this.handleChange.bind(this)}

                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="mail_to_admin_subject">
                              Notification Email Subject Line
                            </label>
                            <input
                              className="form-control"
                              value={mail_to_admin_subject}
                            onChange={this.handleChange.bind(this)}

                              name="mail_to_admin_subject"
                              id="mail_to_admin_subject"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="mail_to_admin_message">
                              Mail to Vendor Message
                            </label>
                            <textarea
                              style={{height:'100px'}}
                              className="form-control"
                              name="mail_to_admin_message"
                            onChange={this.handleChange.bind(this)}

                              id="mail_to_admin_message"
                              required
                              value={mail_to_admin_message}></textarea>
                            <small>
                              You can use variables like:{' '}
                              <code>{'{{PHONE}}'}</code>,{' '}
                              <code>{'{{EMAIL}}'}</code>,{' '}
                              <code>{'{{NAME}}'}</code>,{' '}
                              <code>{'{{LOCATION}}'}</code>,{' '}
                              <code>{'{{LOCATION_LINK}}'}</code>,{' '}
                              <code>{'{{LOCATION_IMAGE}}'}</code>,{' '}
                              <code>{'{{COMPANY}}'}</code>,{' '}
                              <code>{'{{MESSAGE}}'}</code>
                              {extra_vars}
                            </small>
                          </div>
                        </div>
                        <div className="is_a_set">
                          <div className="form-group">
                            <label htmlFor="mail_to_user">
                              Send Email Notification to User?
                            </label>
                            <select
                              className="form-control is_decider"
                            onChange={this.handleChange.bind(this)}

                              value={mail_to_user}
                              name="mail_to_user"
                              id="mail_to_user"
                              required>
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="mail_to_user_subject">
                              User Notification Email Subject Line
                            </label>
                            <input
                              className="form-control is_decider_destination"
                              value={mail_to_user_subject}
                              name="mail_to_user_subject"
                            onChange={this.handleChange.bind(this)}

                              id="mail_to_user_subject"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="mail_to_user_message">
                              User Email Notification content
                            </label>
                            <textarea
                              style={{height:'100px'}}
                              className="form-control is_decider_destination"
                              name="mail_to_user_message"
                              id="mail_to_user_message"
                            onChange={this.handleChange.bind(this)}

                              required
                              value={mail_to_user_message}></textarea>
                            <small>
                              You can use variables like:{' '}
                              <code>{'{{PHONE}}'}</code>,{' '}
                              <code>{'{{EMAIL}}'}</code>,{' '}
                              <code>{'{{NAME}}'}</code>,{' '}
                              <code>{'{{LOCATION}}'}</code>,{' '}
                              <code>{'{{LOCATION_LINK}}'}</code>,{' '}
                              <code>{'{{LOCATION_IMAGE}}'}</code>,{' '}
                              <code>{'{{COMPANY}}'}</code>,{' '}
                              <code>{'{{MESSAGE}}'}</code>
                              {extra_vars}
                            </small>
                          </div>
                        </div>
                        <div className="is_a_set">
                          <div className="form-group">
                            <label htmlFor="mail_to_user">
                              Send Email Notification to Safety Consultants USA?
                            </label>
                            <select
                              className="form-control is_decider"
                              value={mail_to_bermac}
                              name="mail_to_bermac"
                            onChange={this.handleChange.bind(this)}

                              id="mail_to_bermac"
                              required>
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>

                          <div className="form-group">
                            <label htmlFor="mail_to_user_subject">
                            Safety Consultants USA Notification Email Subject Line
                            </label>
                            <input
                              className="form-control is_decider_destination"
                              value={mail_to_bermac_subject}
                              name="mail_to_bermac_subject"
                            onChange={this.handleChange.bind(this)}

                              id="mail_to_bermac_subject"
                              required
                            />
                          </div>

                          <div className="form-group">
                            <label htmlFor="mail_to_user_message">
                              Mail to Safety Consultants USA Team - Message
                            </label>
                            <textarea
                              style={{height:'100px'}}
                              className="form-control is_decider_destination"
                              name="mail_to_bermac_message"
                            onChange={this.handleChange.bind(this)}

                              id="mail_to_bermac_message"
                              required
                              value={mail_to_bermac_message}></textarea>
                            <small>
                              You can use variables like:{' '}
                              <code>{'{{PHONE}}'}</code>,{' '}
                              <code>{'{{EMAIL}}'}</code>,{' '}
                              <code>{'{{NAME}}'}</code>,{' '}
                              <code>{'{{LOCATION}}'}</code>,{' '}
                              <code>{'{{LOCATION_LINK}}'}</code>,{' '}
                              <code>{'{{LOCATION_IMAGE}}'}</code>,{' '}
                              <code>{'{{COMPANY}}'}</code>,{' '}
                              <code>{'{{MESSAGE}}'}</code>
                              {extra_vars}
                            </small>
                          </div>
                        </div>

                        <button
                          type="submit"
                          className="btn btn-dark btn-rounded mr-2"
                          name="submit_to_status_email">
                          Save
                        </button>
                        <button className="btn btn-secondary btn-rounded" type="reset">
                          Reset
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(withParams(FormSettings));
