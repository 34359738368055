import React from "react";
import { connect } from "react-redux";
import Fetcher from "../../actions/Fetcher";
import { Link } from "react-router-dom";
import Messages from "../../components/Messages";

import common from "../../components/common";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useParams } from "react-router-dom";
import moment from "moment/moment";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}
class TeamUsersMy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      page: 1,
      perPage: 20,
      data: [],
      loading: true,
      pagination: {},
    };
  }

  columns = [
    {
      name: "Id",
      sortable: true,
      width: "100px",
      selector: (row) => row.id,
      sortField: "id",
    },
    {
      sortable: true,
      name: "Name",
      sortField: "name",
      selector: (row) => row.name,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      grow: 3,
      sortable: true,
      sortField: "email",
    },

    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
      sortField: "phone",
    },
    {
      name: "Created At",
      selector: (row) => row.created_at,
      sortable: true,
      format: (row) => moment(row.created_at).format("lll"),
      sortField: "created_at",
    },
    {
      sortable: false,
      name: "Actions",
      cell: (u) => (
        <>
          <Link
            className="nav-link badge badge-primary"
            style={{ marginRight: 4 }}
            to={"/users/edit/" + u.id}
            onClick={() => {
              if (window) window.scrollTo(0, 0);

              this.props.dispatch({
                type: "CLEAR_MESSAGES",
              });
            }}
          >
            Edit
          </Link>
          <button
            className={
              "badge " +
              (parseInt(u.status) ? "badge-danger" : " badge-success")
            }
            onClick={() => {
              if (this.state["update_" + u.id]) return;
              if (
                window.confirm(
                  "Are you sure you want to " +
                    (parseInt(u.status) ? "block" : "un-block") +
                    " the user?"
                )
              ) {
                this.setState({ ["update_" + u.id]: true });

                this.updateItem(u.id, {
                  status: parseInt(u.status) ? 0 : 1,
                });
              }
            }}
          >
            {this.state["update_" + u.id]
              ? "please wait..."
              : parseInt(u.status)
              ? "Block User"
              : "Un-Block User"}
          </button>
        </>
      ),
    },
  ];

  updateItem(id, data) {
    Fetcher.post(
      common.teambs + "/update/app_user?&token=" + this.props.token,
      {
        id: id,
        data: data,
        token: this.props.token,
      }
    )
      .then((resp) => {
        this.setState({
          ["update_" + id]: false,
        });
        if (resp.ok) {
          this.props.dispatch({
            type: "SUCCESS",
            messages: [{ ok: true, msg: "Update Successfully." }],
          });
          setTimeout(() => {
            this.props.dispatch({
              type: "CLEAR_MESSAGES",
            });
          }, 2000);
          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({ type: "FAILURE", messages: [resp] });
        }
      })
      .catch((err) => {
        this.setState({
          ["update_" + id]: false,
        });
        console.log(err);
        const msg = "Failed to perform Action";
        this.props.dispatch({
          type: "FAILURE",
          messages: [{ msg: msg }],
        });
      });
  }

  async componentDidMount() {
    this.handlePageChange(this.state.page);
  }

  handleSort = async (a, b) => {
    this.setState(
      {
        sort: a.sortField,
        sort_order: b,
      },
      () => {
        this.handlePageChange(1);
      }
    );
  };

  handleOnChangeRowsPerPage = async (page) => {
    this.setState(
      {
        perPage: page,
      },
      () => {
        this.handlePageChange(1);
      }
    );
  };

  handlePageChange = async (page) => {
    const { perPage } = this.state;
    this.setState({ loading: true });
    let sort = "";
    if (this.state.sort) {
      sort = "&sort=" + this.state.sort + "&order=" + this.state.sort_order;
    }
    const response = await axios.get(
      `${common.teambs}/listPaged/app_user?my=yes&page=${page}&per_page=${perPage}&delay=1${sort}&token=${this.props.token}`
    );
    // console.log(response);
    this.setState({
      items: response.data.items,
      page: page,
      totalRows:
        response.data.pagination.pageCount * response.data.pagination.pageSize,
      loading: false,
    });
  };

  render() {
    const { totalRows, loading } = this.state;

    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <Messages messages={this.props.messages} />
            <DataTable
              title={"Users"}
              columns={this.columns}
              data={this.state.items}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 75, 100, 200, 500]}
              highlightOnHover
              paginationPerPage={this.state.perPage}
              fixedHeader
              noContextMenu
              fixedHeaderScrollHeight={"70vh"}
              pagination
              progressPending={loading}
              persistTableHead
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleOnChangeRowsPerPage}
              onSort={this.handleSort}
              sortServer
              actions={[
                <Link
                  className="btn btn-dark btn-rounded"
                  to="/add-user"
                  key="add-user"
                >
                  <i className="fa-solid fa-plus"></i> New user
                </Link>,
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(withParams(TeamUsersMy));
