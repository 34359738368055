import React from 'react';
import NavBar from './NavBar';
import Header from './Header';
import {connect} from 'react-redux';
import {Outlet} from 'react-router-dom';

class App extends React.Component {
  render() {
    return (
      <div className="dashboard-main-wrapper">
        {this.props.user && this.props.user.id ? (
          <div className="nav-left-sidebar sidebar-light">
            <div className="menu-list">
              <NavBar />
            </div>
          </div>
        ) : (
          false
        )}
        <div
          className="dashboard-wrapper"
          style={
            this.props.user && this.props.user.id
              ? {}
              : {width: '100%', margin: 0}
          }>
          <div className="dashboard-ecommerce">
            <Header />
            <Outlet />
          </div>
        </div>
        {/* <Footer /> */}
        {/* <ModalContainer /> */}
      </div>
    );
  }
}

// export default App;

const mapStateToProps = state => {
  return {
    // modals: state.modals.modals,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(App);
