import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../../actions/auth";
// import { facebookLogin, twitterLogin, googleLogin, vkLogin, githubLogin } from '../../actions/oauth';
import Messages from "../../components/Messages";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loginAs: "",
    };
    // this.state = {email: 'kate@bermacsafety.com', password: '5ms924142'};
    // this.state = {email: 'admin@test.com', password: '5ms924142'};
    // this.state = { loginAs:"admin"};
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleLogin(event) {
    event.preventDefault();
    this.props.dispatch(
      login(this.state.email, this.state.password, this.state.loginAs)
    );
  }

  render() {
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-4 offset-md-4">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="page-header">
                  <h2 className="pageheader-title">Login </h2>
                </div>
              </div>
            </div>
            <Messages messages={this.props.messages} />
            <form onSubmit={this.handleLogin.bind(this)}>
              {/* <legend>Log In</legend> */}
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  autoFocus
                  className="form-control"
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  className="form-control"
                  value={this.state.password}
                  onChange={this.handleChange.bind(this)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Login As</label>
                <select
                  className="form-control"
                  id="loginAs"
                  onChange={this.handleChange.bind(this)}
                  value={this.state.loginAs}
                  required
                  name="loginAs"
                >
                  <option value="">Choose</option>
                  <option value={"team"}>Subscriber</option>
                  <option value={"admin"}>Admin</option>
                </select>
              </div>
              <div
                className="form-group"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Link to="/forgot">
                  <strong>Forgot your password?</strong>
                </Link>
                <Link to="/register">
                  <strong>Sign up as a new Subscriber</strong>
                </Link>
              </div>
              <button type="submit" className="btn btn-primary">
                Log in
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(Login);
