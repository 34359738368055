import React from 'react';
import {connect} from 'react-redux';
import Fetcher from '../../actions/Fetcher';
import {Link} from 'react-router-dom'
import Messages from '../../components/Messages'; 

import common from '../../components/common';
import DataTable from 'react-data-table-component';
import axios from 'axios';

class Plans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      page: 1,
      perPage: 10,
      data: [],
      loading: true,
      pagination: {},
    };
  }

  columns = [
    {name: '#', sortable: true, width:"80px", selector: row => row.id, sortField:'id'},
    {
      sortable: true,
      name: 'Plan Name',
      selector: row => row.plan_name, sortField:'plan_name'
    },
    {name: 'Plan Cost', selector: row => row.plan_cost,sortable: true, sortField:'plan_cost'},
    {name: 'Plan Term', selector: row => row.plan_term,sortable: true, sortField:'plan_term'},
    {name: 'Stripe Id', selector: row => row.plan_stripe_id,sortable: true, sortField:'plan_stripe_id'},
    {name: 'Max Users', selector: row => row.max_users,sortable: true, sortField:'max_users'},
    
    {
      allowOverflow: true,
      sortable : true,
      // selector: "actions",
      name: 'Actions',
      cell: u => (
        <div className="dropdown">
          <button
            className="btn btn-primary btn-dark btn-rounded btn-xs dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            Actions
            <span className="caret" />
          </button>
          <div className="dropdown-menu">
          {/* <h6 className="dropdown-header">Actions</h6> */}

              <Link  className="dropdown-item"
                to={"/plan/edit/"+u.id}
                
              >
                Edit plan
              </Link>
            

            
              <Link  className="dropdown-item text-danger confirm"
                to={"/plans/"}
                onClick={e=>{
                  if(this.state['delete_' + u.id])return;
                  if(window.confirm('Are tou sure you want to delete the plan?'))
                  {
                    this.setState({['delete_' + u.id]: true})
                    this.deleteItem(u.id);
                  }
                }}
                
              >
                {this.state['delete_' + u.id] ? 'please wait...':'Delete Plan'}
              </Link>
          </div>
        </div>
      )

    },
  ];

  
  deleteItem(id) {
    Fetcher.post(common.bs + `/delete/plan?token=${this.props.token}`, {
      id: id,
    })
      .then(resp => {
        this.setState({
          ['delete_' + id]: false,
        });
        if (resp.ok) {
          this.props.dispatch({
            type: 'SUCCESS',
            messages: [{ok: true, msg: 'Deleted Successfully.'}],
          });
          setTimeout(()=>{
            this.props.dispatch({
              type: 'CLEAR_MESSAGES',
            });
          }, 2000);
          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({type: 'FAILURE', messages: [resp]});
        }
      })
      .catch(err => {
        console.log(err);
        const msg = 'Failed to perform Action';
        this.props.dispatch({
          type: 'FAILURE',
          messages: [{msg: msg}],
        });
        setTimeout(()=>{
          this.props.dispatch({
            type: 'CLEAR_MESSAGES',
          });
        }, 2000);
      });
  }

  async componentDidMount() {
    this.handlePageChange(this.state.page);
  }

  handleSort = async (a, b) => {
    this.setState(
      {
        sort: a.sortField,
        sort_order: b,
      },
      () => {
        this.handlePageChange(1);
      }
    );
  };

  handleOnChangeRowsPerPage = async page => {
    this.setState(
      {
        perPage: page,
      },
      () => {
        this.handlePageChange(1);
      },
    );
  };

  handlePageChange = async page => {
    const {perPage} = this.state;

    this.setState({loading: true});
    let sort = '';
    if (this.state.sort) {
      sort = "&sort=" + this.state.sort + "&order=" + this.state.sort_order;
    }
    const response = await axios.get(
      `${common.bs}/listPaged/plan?page=${page}&per_page=${perPage}${sort}&token=${this.props.token}`,
    );
    this.setState({
      items: response.data.items,
      page: page,
      totalRows:
        response.data.pagination.pageCount * response.data.pagination.pageSize,
      loading: false,
    });
  };

  render() {
    const {totalRows, loading} = this.state;
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <Messages messages={this.props.messages} />
            <DataTable
              title={'Plans'}
              columns={this.columns}
              data={this.state.items}
              paginationRowsPerPageOptions={[10, 20, 25, 50, 75, 100, 200, 500]}
              highlightOnHover
              fixedHeader
              noContextMenu
              fixedHeaderScrollHeight={'70vh'}
              pagination
              progressPending={loading}
              persistTableHead
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleOnChangeRowsPerPage}
              onSort={this.handleSort}
                // rowsPerPage={this.state.perPage}
              // page={ this.state.page}
              // count={totalRows}
              sortServer
              actions={[
                <Link className="btn btn-dark btn-rounded" to="/new-plan" key={'new-plan'}>
                  New Plan
                </Link>,
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(Plans);
