import React from 'react';
import {connect} from 'react-redux';
import Fetcher from '../../actions/Fetcher';
// import {Link} from 'react-router-dom'
import Messages from '../../components/Messages'; 

import common from '../../components/common';
// import DataTable from 'react-data-table-component';
import axios from 'axios';



class SafetyContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      loading: true,
    };
  }

  save(i, obj, toDelete)
  {
    this.setState({
      ['saving_'+i] : true
    })
    let val = this.state.originalObj;
    val = JSON.parse(val);
    val[i] = obj;
    this.updateItem(this.state.originalObjId, {
      config_value : JSON.stringify(val)
    }, i, toDelete)
  }
  
  updateItem(id, data, io, toDelete) {
    Fetcher.post(common.bs + '/update/config?token='+this.props.token, {
      id: id,
      data: data,
      token: this.props.token,
    })
      .then(resp => {
        this.setState({
          ['saving_'+io] : false,
          [toDelete+'_email']:false,
          [toDelete+'_name']:false,
          [toDelete+'_phone']:false,
        })
        if (resp.ok) {
          this.props.dispatch({
            type: 'SUCCESS',
            messages: [{ok: true, msg: 'Update Successfully.'}],
          });

          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({type: 'FAILURE', messages: [resp]});
        }
      })
      .catch(err => {
        this.setState({
          ['saving_'+io] : false
        })
        console.log(err);
        const msg = 'Failed to perform Action';
        this.props.dispatch({
          type: 'FAILURE',
          messages: [{msg: msg}],
        });
      });
  }

  async componentDidMount() {
    this.handlePageChange(this.state.page);
  }

  handlePageChange = async page => {
    this.setState({loading: true});
    const response = await axios.get(
      `${common.bs}/listPaged/config?page=1&per_page=100&token=${this.props.token}`,
    );
    let items = response.data.items;
    items = items.filter((item)=>item.config_key ==='contact_person'?true:false);
    
    let originalObj = items[0].config_value;
    let originalObjId = items[0].id;
    items = items[0].config_value;
    if(!items)items='[]';
    items = JSON.parse(items);
    this.setState({
      items: items,
      originalObj: originalObj,
      originalObjId: originalObjId,
      page: page,
      loading: false,
    });
  };

  render() {
    return (

      <div className="container-fluid dashboard-content">
      <div className="row">
        <div className="col-md-12" style={{background:'#fff', paddingBottom:20, paddingTop:20}}>
          <Messages messages={this.props.messages} />

<h4>Safety Contacts For App Users <small>(Contacts will appear in the order listed below)</small></h4>
          <table className='table ' style={{width:'100%'}}>
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th></th>
              </tr>
              
            </thead>
            <tbody>
              {
                this.state.items ? this.state.items.map((row, i)=>{
                  return (
                    <tr key={i}>
                      <td>Safety Person #{i+1}</td>
                      <td>
                      <input type="text" className="form-control" value={this.state['data'+'_'+i+'_name'] || row.name} onChange={e=>{
          this.setState({['data'+'_'+i+'_name'] : e.target.value});
        }} />
                      </td>
                      <td>
                      <input type="text" className="form-control" value={this.state['data'+'_'+i+'_email'] || row.email} onChange={e=>{
          this.setState({['data'+'_'+i+'_email'] : e.target.value});
        }} />
                      </td>
                      <td>
                      <input type="text" className="form-control" value={this.state['data'+'_'+i+'_phone'] || row.phone} onChange={e=>{
          this.setState({['data'+'_'+i+'_phone'] : e.target.value});
        }} />
                      </td> 
                      <td>
            
        <button disabled={!this.state['data_'+i+'_email'] && !this.state['data_'+i+'_phone'] && !this.state['data_'+i+'_name']} className="btn btn-success btn-rounded" type="button" id="button-addon2" onClick={e=>{
          if(this.state['saving_'+i])return;
          this.save(i, {
            name: this.state['data_'+i+'_name'] || row.name,
            phone: this.state['data_'+i+'_phone'] || row.phone,
            email: this.state['data_'+i+'_email'] || row.email,
        }, 'data_'+i);
        }}>{this.state['saving_'+i] ? 'please wait...': 'Save'}</button>
                      </td>
                    </tr>
                  )
                }):false
              }
            </tbody>
            </table>
          
        </div>
      </div>
    </div>


    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    // user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(SafetyContacts);
