import React from 'react';
import {connect} from 'react-redux';
import {logout} from '../../actions/auth'
class Logout extends React.Component{


  render(){
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">

          <div className="row">
  <div className="col-md-8 grid-margin stretch-card">
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">
         logout!
        </h4>
        <div className="card-description">
          <p>
            Are you sure you want to logout?
          </p>
        </div>
      </div>
      <div className="card-footer">
        <button className="btn btn-danger btn-rounded" onClick={()=>{
          this.props.dispatch(logout());
          this.setState({
            logging_out : true
          })
          setTimeout(function(){
            window.location.href="/";
          }, 1000)
        }}>Yes Please!</button>
      </div>
    </div>
  </div>
</div>

          </div>
          </div>
          </div>
    )
  }
}



const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(Logout);
