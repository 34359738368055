import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import Messages from '../../components/Messages';
import common from '../../components/common';

class SubscriberNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      email: '',
      name: '',
      phone: '',
      password: '',
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  async createSubscriber(e) {
    e.preventDefault();

    let {email, name, phone, password} =
      this.state;
    let obj = {
      team_id : this.props.user.id,
      email,
      name,
      phone,
      password,
    };
    let send_data = {
      data: obj,
    };

    let url = `${common.teambs}/createCustom/app_user?token=${this.props.token}`;

    const response = await axios.post(url, send_data);
    // console.log(response)
    let data = response.data;
    if (data.ok) {
      this.setState({
        email: '',
        name: '',
        phone: '',
        password: '',
      });
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: 'App user Account Created successfully.'}],
      });

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to create App user Account'}],
      });
    }
  }

  render() {
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h4>{'New User'}</h4>

            <Messages messages={this.props.messages} />

            <hr />
            <br />

            <form onSubmit={this.createSubscriber.bind(this)}>
              <div className="row">
                <div className="col-md-12">


                <div className="form-group">
                    <label>Full Name</label>
                    <input
                      value={this.state.name}
                      type="text"
                      required
                      name="name"
                      id="name"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>


                  <div className="form-group">
                    <label>Email</label>
                    <input
                      value={this.state.email}
                      type="email"
                      required
                      name="email"
                      id="email"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>
 

                  <div className="form-group">
                    <label>Phone Number</label>
                    <input
                      value={this.state.phone}
                      type="tel"
                      required
                      name="phone"
                      id="phone"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <input
                      value={this.state.password}
                      type="text"
                      required
                      name="password"
                      id="password"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label>Team Id</label>
                    <input
                      value={this.props.user.id}
                      type="text"
                      readOnly
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <p>
                <input
                  type="submit"
                  className="btn btn-dark btn-rounded"
                  value="Create User"
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(SubscriberNew);
