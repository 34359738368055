import React from 'react';

import {connect} from 'react-redux';
import common from '../../components/common';
import Messages from '../../components/Messages';
import axios from 'axios';
// import {Link} from 'react-router-dom';
import moment from 'moment';
import {useParams} from 'react-router-dom';

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class MyProfile extends React.Component {
  state = {
    user: {},
    history: [],
    expires_on: '',
    new_password: '',
    confirm_password: '',
    plans: [],
  };
  async componentDidMount() {
    this.fetchPlans();
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  async changePassword(e) {
    e.preventDefault();
    let url = `${common.bs}/update/team?token=${this.props.token}`;

    const response = await axios.post(url, {
      id : this.props.params.teamId,
      data: {
        password: this.state.new_password
      }

    });
    let data = response.data;
    if (data.ok) {
      this.fetchData();
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{ok: true, msg: 'Password Updated Successfully.'}],
      });
      setTimeout(() => {
        this.props.dispatch({
          type: 'CLEAR_MESSAGES',
        });
      }, 2000);
    } else {
    }
  }

  async upgradeByAdmin(e)
  {
    e.preventDefault();
    let teamId = this.props.params.teamId;
    let selectedPlan = this.state.selectedPlan;
    let expires_on = this.state.expires_on;
    let ex = '';
    if(expires_on)
    {
      ex = '&expires_on='+this.state.expires_on;
    }
    let url = `${common.bs}/upgradeByAdmin?team_id=${teamId}&token=${this.props.token}&selectedPlan=${selectedPlan}`;
    url+=ex;

    const response = await axios.get(url);
    let data = response.data;
    if (data.ok) {
      this.fetchData();
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{ok: true, msg: 'Updated Successfully.'}],
      });
      setTimeout(() => {
        this.props.dispatch({
          type: 'CLEAR_MESSAGES',
        });
      }, 2000);
    } else {
    }
  }
  async convertToFree(e) {
    e.preventDefault();
    let teamId = this.props.params.teamId;
    let url = `${common.bs}/convertToFree?team_id=${teamId}&token=${this.props.token}`;

    const response = await axios.get(url);
    let data = response.data;
    if (data.ok) {
      this.fetchData();
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{ok: true, msg: 'Updated Successfully.'}],
      });
      setTimeout(() => {
        this.props.dispatch({
          type: 'CLEAR_MESSAGES',
        });
      }, 2000);
    } else {
    }
  }

  cancelMembershipImmediately(e) {
    e.preventDefault();
    // let teamId = this.props.params.teamId;
  }

  cancelMembership(e) {
    e.preventDefault();
    // let teamId = this.props.params.teamId;
  }

  resumeMembership(e) {
    e.preventDefault();
    // let teamId = this.props.params.teamId;
  }
  async fetchData() {
    let teamId = this.props.params.teamId;
    let url = `${common.bs}/listPaged/team?filter_id=${teamId}&token=${this.props.token}&my=yes&myId=id`;

    const response = await axios.get(url);
    let data = response.data;
    if (data.items && data.items.length) {
      this.setState(
        {
          user: data.items[0],
        },
        this.fetchSubHistory,
      );
    }
  }

  async fetchPlans() {
    let url = `${common.bs}/listPaged/plan?token=${this.props.token}`;

    const response = await axios.get(url);
    let data = response.data;
    if (data.items && data.items.length) {
      this.setState(
        {
          plans: data.items,
        },
        this.fetchData,
      );
    }
  }

  async fetchSubHistory() {
    let teamId = this.props.params.teamId;
    let url = `${common.bs}/listPaged/subscription_history?filter_team_id=${teamId}&token=${this.props.token}&my=yes`;

    const response = await axios.get(url);
    let data = response.data;
    if (data.items && data.items.length) {
      this.setState(
        {
          history: data.items,
        },
        this.fetchSubData,
      );
    }
  }

  async fetchSubData() {
    let subscription_id = this.state.user.subscription_id;
    if (subscription_id) {
      let url = `${common.bs}/getSubData?token=${this.props.token}&sub_id=${subscription_id}`;

      const response = await axios.get(url);
      let data = response.data;
      if (data.data) {
        this.setState({
          subscriptionData: data.data,
        });
      }
    }
  }

  async modifyPlan(e){
    e.preventDefault();
    let selectedPlan = this.state.selectedPlan;
    let teamId = this.props.params.teamId;
    // let expires_on = this.state.expires_on;
    // let ex = '';
    // if(expires_on)
    // {
    //   ex = '&expires_on='+this.state.expires_on;
    // }
    let url = `${common.bs}/upgradeByAdmin?team_id=${teamId}&token=${this.props.token}&selectedPlan=${selectedPlan}`;
    // url+=ex;

    const response = await axios.get(url);
    let data = response.data;
    if (data.ok) {
      this.fetchData();
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{ok: true, msg: 'Updated Successfully.'}],
      });
      setTimeout(() => {
        this.props.dispatch({
          type: 'CLEAR_MESSAGES',
        });
      }, 2000);
    } else {
    }
  }

  render() {
    const {user, history, subscriptionData, plans} = this.state;
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <Messages messages={this.props.messages} />
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">
                      Subscriber Account Number - #{user.id}{' '}
                    </h4>
                    <div className="card-description">
                      <table className="table table-stripped table-hover nodt">
                        <tbody>
                          <tr>
                            <th>Account Number</th>
                            <td>{user.id}</td>
                          </tr>
                          <tr>
                            <th>Account Administrator</th>
                            <td>
                              {user.first_name} {user.last_name}
                            </td>
                          </tr>
                          <tr>
                            <th>Account Name</th>
                            <td>{user.account_name}</td>
                          </tr>
                          <tr>
                            <th>Email</th>
                            <td>{user.email}</td>
                          </tr>
                          <tr>
                            <th>Phone</th>
                            <td>{user.phone}</td>
                          </tr>
                          <tr>
                            <th>Subscription Active</th>
                            <td>{user.is_paid ? 'Yes' : 'No'}</td>
                          </tr>
                          <tr>
                            <th>Plan Type</th>
                            <td>
                              {user.plan === 'upgrade_by_admin'
                                ? 'Manual Upgrade by Admin'
                                : user.plan}{' '}
                              {user.plan === 'paid'
                                ? '(' + user.plan_obj + ')'
                                : false}
                            </td>
                          </tr>

                          {user.plan === 'paid' ? (
                            <>
                              <tr>
                                <th>Subscription Activation Date</th>
                                <td>
                                  {subscriptionData
                                    ? moment(subscriptionData.created).format(
                                        'lll',
                                      )
                                    : '--'}
                                </td>
                              </tr>

                              <tr>
                                <th>Current Billing Cycle Began </th>
                                <td>
                                  {subscriptionData
                                    ? moment(
                                        subscriptionData.current_period_start,
                                      ).format('lll')
                                    : '--'}
                                </td>
                              </tr>

                              <tr>
                                <th>
                                  {subscriptionData &&
                                  subscriptionData.cancel_at_period_end ? (
                                    <th>Subscription Stops on</th>
                                  ) : (
                                    <th>Next Billing Cycle Begins</th>
                                  )}
                                </th>{' '}
                                <td>
                                  {subscriptionData
                                    ? moment(
                                        subscriptionData.current_period_end,
                                      ).format('lll')
                                    : '--'}
                                </td>
                              </tr>

                              {subscriptionData &&
                              subscriptionData.trial_end ? (
                                <tr>
                                  <th>Trial Ends on</th>
                                  <td>
                                    {moment(subscriptionData.trial_end).format(
                                      'lll',
                                    )}
                                  </td>
                                </tr>
                              ) : (
                                false
                              )}
                            </>
                          ) : (
                            <>
                            <tr>
                              <th>Plan Max Users</th>
                              <td>
                                {user.max_users} { }
                              </td>
                            </tr>
                            <tr>
                            <th>Plan Expires on</th>
                            <td>
                              {'Unless Cancelled by admin or by subscriber'}
                            </td>
                          </tr>
                          </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Subscription History</h4>
                    <div className="card-description">
                      <table className="table table-stripped table-hover">
                        <thead>
                          <tr>
                            <th> </th>
                            <th>Date</th>
                            <th>Description</th>
                          </tr>
                        </thead>

                        <tbody>
                          {history &&
                            history.map((item, i) => {
                              return (
                                <tr key={item.id}>
                                  <td>{item.id}</td>
                                  <td>
                                    {moment(item.changed_on).format('lll')}
                                  </td>
                                  <td>{item.descr}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Modify/Cancel Subscription</h4>
                    <div className="card-description">
                      <div className="row">
                        {user.plan === 'paid' || user.plan === 'upgrade_by_admin' ? (
                          <div className="col-md-6">
                            <h4>Modify Plan </h4>
                            <form
                              method="post"
                              onSubmit={this.modifyPlan.bind(this)}>
                              <div className="form-group">
                                {/* {user.plan} */}
                                <select
                                  id="example2-card-plan"
                                  name="change_plan_selection"
                                  onChange={e=>{
                                    this.setState({
                                      selectedPlan : e.target.value
                                    })
                                  }}
                                  value={this.state.selectedPlan}
                                  className="form-control">
                                  <option value="">Select New Plan</option>

                                  {plans
                                    ? plans.map((item, i) => {
                                        return (
                                          <option
                                            value={
                                              item.plan_stripe_id +
                                              '|' +
                                              item.max_users
                                            }>
                                            {item.plan_name} (
                                            ${item.plan_cost}/{item.plan_term} )
                                          </option>
                                        );
                                      })
                                    : false}
                                </select>
                              </div>
                              <p>
                                <input
                                  type="submit"
                                  className="btn btn-dark btn-rounded"
                                  value="Change Membership"
                                />
                              </p>
                            </form>
                          </div>
                        ) : (
                          false
                        )}
                        <div className="col-md-6">
                          {user.plan === 'paid' ? (
                            <>
                              <h4>Stop paid membership ? </h4>
                              <div className="row">
                                <div className="col-md-5">
                                  {subscriptionData &&
                                  subscriptionData.cancel_at_period_end ? (
                                    <>
                                      <p>
                                        Your subscription is scheduled to stop
                                        on{' '}
                                        {moment(
                                          subscriptionData.current_period_end,
                                        ).format('lll')}
                                      </p>

                                      <form
                                        method="post"
                                        onSubmit={this.resumeMembership.bind(
                                          this,
                                        )}>
                                        <p>
                                          <input
                                            required
                                            type="hidden"
                                            name="do_cancel_plan"
                                            value="resume"
                                          />
                                          <input
                                            type="submit"
                                            className="btn btn-primary"
                                            value="Undo this Action"
                                            style={{marginTop: 5}}
                                          />
                                        </p>
                                      </form>
                                    </>
                                  ) : (
                                    <form
                                      method="post"
                                      onSubmit={this.cancelMemberShip.bind(
                                        this,
                                      )}>
                                      <div className="form-group">
                                        <label>
                                          <input
                                            required
                                            type="checkbox"
                                            name="do_cancel_plan"
                                            value="free"
                                          />{' '}
                                          Check this option if you want to stop
                                          this subscriber's membership at period end.
                                        </label>
                                      </div>
                                      <p>
                                        <input
                                          type="submit"
                                          className="btn btn-danger"
                                          value="Cancel membership"
                                        />
                                      </p>
                                    </form>
                                  )}
                                </div>
                                <div className="col-md-2">
                                  <span className="or_msg">OR</span>
                                </div>
                                <div className="col-md-5">
                                  <form
                                    method="post"
                                    onSubmit={this.cancelMembershipImmediately.bind(
                                      this,
                                    )}>
                                    <div className="form-group">
                                      <label>
                                        <input
                                          required
                                          type="checkbox"
                                          name="do_cancel_plan_now"
                                          value="free"
                                        />{' '}
                                        Check this option if you want to stop
                                        your membership immediately.
                                      </label>
                                    </div>
                                    <p>
                                      <input
                                        type="submit"
                                        className="btn btn-danger"
                                        value="Immediately Stop Membership"
                                      />
                                    </p>
                                  </form>
                                </div>
                              </div>
                            </>
                          ) : user.plan === 'free' ? (
                            <form method="post" onSubmit={this.upgradeByAdmin.bind(this)}>
                      <div className="form-group">
                        <input required type="checkbox" name="plan" value="upgrade_by_admin" /> Premium (upgrade by admin OR payment received manually)
                      </div>
                      <div className="form-group">

                      <select
                                  id="example2-card-plan"
                                  name="change_plan_selection"
                                  onChange={e=>{
                                    this.setState({
                                      selectedPlan : e.target.value
                                    })
                                  }}
                                  value={this.state.selectedPlan}
                                  className="form-control">
                                  <option value="">Select New Plan</option>

                                  {plans
                                    ? plans.map((item, i) => {
                                        return (
                                          <option
                                            value={
                                              item.plan_stripe_id +
                                              '|' +
                                              item.max_users
                                            }>
                                            {item.plan_name} (
                                            ${item.plan_cost}/{item.plan_term} )
                                          </option>
                                        );
                                      })
                                    : false}
                                </select>
                      </div>

                      {/* <div className="form-group">
                        <label>Expires on <small>(Optional)</small></label>
                        <input className="form-control" name="expires_on" type="date" value={this.state.expires_on} onChange={
                          (e)=>{
                            this.setState({
                              expires_on: e.target.value
                            })
                          }
                        }  />
                      </div> */}

                      <p>
                      <input type="submit" className="btn btn-dark btn-rounded" value="Change Membership" /> 
                      </p>
                    </form>
                            
                          ) : user.plan === 'upgrade_by_admin' ? (
                            <>
                              <h4>Stop membership ? </h4>
                              <form
                                method="post"
                                onSubmit={this.convertToFree.bind(this)}>
                                <div className="form-group">
                                  <label>
                                    <input
                                      required
                                      type="checkbox"
                                      name="plan"
                                      value="free"
                                    />{' '}
                                    Check this option if you want to stop 
                                    membership of this subscriber.
                                  </label>
                                </div>

                                <p>
                                  <button
                                    type="submit"
                                    className="btn btn-danger btn-rounded">
                                    Stop Membership
                                  </button>
                                </p>
                              </form>
                            </>
                          ) : (
                            false
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Change Password</h4>
                    <div className="card-description">
                      <form
                        method="post"
                        onSubmit={this.changePassword.bind(this)}>
                        <div className="form-group">
                          <input
                            required
                            type="password"
                            name="new_password"
                            id="new_password"
                            value={this.state.new_password}
                            onChange={this.handleChange.bind(this)}
                            placeholder="Enter new password"
                            className="form-control"
                          />
                        </div>

                        <div className="form-group">
                          <input
                            required
                            type="password"
                            name="confirm_password"
                            id="confirm_password"
                            value={this.state.confirm_password}
                            onChange={this.handleChange.bind(this)}
                            placeholder="Re-Enter new password"
                            className="form-control"
                          />
                        </div>

                        <p>
                          <input
                            type="submit"
                            disabled={
                              this.state.new_password !==
                              this.state.confirm_password
                            }
                            className="btn btn-dark btn-rounded"
                            value="Change Password"
                          />
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(withParams(MyProfile));
