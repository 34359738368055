import React from 'react';
import {connect} from 'react-redux';
import {logout} from '../actions/auth';
import {Link} from 'react-router-dom'


class Header extends React.Component {
  state  = {}
  handleLogout(event) {
    event.preventDefault();
    this.props.dispatch(logout());
    setTimeout(function(){
      
      window.location.reload();
    }, 1000);
  }

  links = [  ];
  
  render() {
    return (
      
      <nav className="navbar navbar-expand-lg bg-white">
        {
          this.props.user && this.props.user.id ?  false: <a className="navbar-brand" href="/app/">
          <img src="/assets/images/logo.png" style={{height:50, width:'auto'}}  alt="logo" />
        </a>
        }
      
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
      </button>
      {
        this.props.user && this.props.user.id ? <div className="collapse navbar-collapse " id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto navbar-right-top">
            <li className="nav-item"></li>
            <li className="nav-item dropdown nav-user">
                <a className="nav-link nav-user-img" href="/app/profile" id="navbarDropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={(e)=>{
                  e.preventDefault();
                  this.setState({showingMenu: !this.state.showingMenu})
                }}>
                  <img src={'https://ui-avatars.com/api/?name='+this.props.user.first_name+'+'+this.props.user.last_name+'&background=FF544F&color=fff'} alt="" className="user-avatar-md rounded-circle" />
                </a>
                <div className={ (this.state.showingMenu ? ' show ' : '')  + "dropdown-menu dropdown-menu-right nav-user-dropdown"} aria-labelledby="navbarDropdownMenuLink2">
                    <div className="nav-user-info">
                        <h5 className="mb-0 text-white nav-user-name">{this.props.user.first_name ? this.props.user.first_name : ''} {this.props.user.last_name ? this.props.user.last_name : ''}</h5>
                    </div>
                    <Link className="dropdown-item" to="/logout" ><i className="fas fa-power-off mr-2"></i>Logout</Link>
                </div>
                {/* {JSON.stringify(this.props.user)} */}
            </li>
        </ul>
    </div> : false
      }
      
  </nav>
     )
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user
  };
};

export default connect(mapStateToProps)(Header);
