import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import Messages from "../../components/Messages";
import common from "../../components/common";
import { useParams } from "react-router-dom";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class SubscriberEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      email: "",
      name: "",
      phone: "",
      password: "",
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params.userId !== this.props.params.userId) {
      this.fetchData();
      // console.log('something prop has changed.')
    }
  }

  async componentDidMount() {
    this.fetchData();
  }
  async fetchData() {
    let userId = this.props.params.userId;

    let url = `${common.teambs}/listPaged/app_user?filter_id=${userId}&token=${this.props.token}`;

    const response = await axios.get(url);

    let data = response.data;
    let user = data?.items?.[0];
    if (!user) {
      this.setState({ noUser: true });
      return;
    }
    if (user.team_id != this.props.user.id) {
      this.setState({ noTeamSame: true, noUser: false });
      return;
    } else {
      this.setState({
        noTeamSame: false,
        noUser: false,
        name: user.name,
        email: user.email,
        phone: user.phone,
      });
    }
  }

  async updateUser(e) {
    e.preventDefault();

    let { email, name, phone, password } = this.state;
    let obj = {
      team_id: this.props.user.id,
      email,
      name,
      phone,
      // password,
    };
    if (password) {
      obj.password = password;
    }
    let send_data = {
      data: obj,
      id: this.props.params.userId,
    };

    let url = `${common.teambs}/update/app_user?token=${this.props.token}`;

    const response = await axios.post(url, send_data);
    // console.log(response)
    let data = response.data;
    if (data.ok) {
      // this.setState({
      //   email: "",
      //   name: "",
      //   phone: "",
      //   password: "",
      // });
      this.props.dispatch({
        type: "SUCCESS",
        messages: [{ msg: "App user Account updated successfully." }],
      });

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: "FAILURE",
        messages: [{ msg: data.msg || "Failed to update App user Account" }],
      });
    }
  }

  render() {
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h4>{"Update User"}</h4>

            <Messages messages={this.props.messages} />

            {this.state.noUser ? (
              <div className="alert alert-danger">No such user exists</div>
            ) : (
              false
            )}
            {this.state.noTeamSame ? (
              <div className="alert alert-danger">
                User not part of the team. Can not edit
              </div>
            ) : (
              false
            )}

            <hr />
            <br />
            {this.state.noTeamSame || this.state.noUser ? (
              false
            ) : (
              <form onSubmit={this.updateUser.bind(this)}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Full Name</label>
                      <input
                        value={this.state.name}
                        type="text"
                        required
                        name="name"
                        id="name"
                        onChange={this.handleChange.bind(this)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label>Email</label>
                      <input
                        value={this.state.email}
                        type="email"
                        required
                        name="email"
                        id="email"
                        onChange={this.handleChange.bind(this)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        value={this.state.phone}
                        type="tel"
                        required
                        name="phone"
                        id="phone"
                        onChange={this.handleChange.bind(this)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label>
                        Password{" "}
                        <small>
                          (Optional: Enter password only if you wish to change
                          it)
                        </small>
                      </label>
                      <input
                        value={this.state.password}
                        type="text"
                        // required
                        name="password"
                        id="password"
                        onChange={this.handleChange.bind(this)}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <label>Team Id</label>
                      <input
                        value={this.props.user.id}
                        type="text"
                        readOnly
                        disabled
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>

                <p>
                  <input
                    type="submit"
                    className="btn btn-dark btn-rounded"
                    value="Update User"
                  />
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(withParams(SubscriberEdit));
