import {Provider} from 'react-redux';
import configureStore from './store/configureStore';
import {BrowserRouter, Route, Navigate, Routes} from 'react-router-dom';
import App from './components/App';
import Login from './screens/auth/Login';
import Register from './screens/auth/Signup'
import NotFound from './screens/NotFound';
// import Profile from './screens/auth/Profile';

import history from './history';
import SubscribersAll from './screens/admin/SubscribersAll';
import Configuration from './screens/admin/Configuration';
import SafetyContacts from './screens/admin/SafetyContacts';
import Plans from './screens/admin/Plans';
import Users from './screens/admin/Users';
import TeamProfile from './screens/admin/Profile';
import PlanNew from './screens/admin/PlanNew';
import PlanEdit from './screens/admin/PlanEdit';
import SubscriberNew from './screens/admin/SubscriberNew';
import TeamData from './screens/admin/TeamData';
import ButtonData from './screens/admin/ButtonData';
import FormData from './screens/admin/FormData';


import AppDashboardSettings from './screens/team/AppDashboardSettings';
import UsersMy from './screens/team/UsersMy';
import UsersNew from './screens/team/UsersNew';
import UsersEdit from './screens/team/UsersEdit'
import ButtonPressSettings from './screens/team/ButtonPressSettings';
import SafetyContactsMy from './screens/team/SafetyContactsMy';
import ButtonPressData from './screens/team/ButtonPressData';
import FormDataMy from './screens/team/FormDataMy';
import Logout from './screens/team/Logout';
import FormSettings from './screens/team/FormSettings';
import MyProfile from './screens/team/MyProfile';
import Purchase from './screens/team/Purchase';



let userInfo = window.localStorage.getItem('userInfo');
if (userInfo) userInfo = JSON.parse(userInfo);
let userRole = window.localStorage.getItem('userRole');
let userToken = window.localStorage.getItem('userToken');

const store = configureStore({
  auth:
    userToken && userRole && userInfo
      ? {
          token: userToken,
          role: userRole,
          user: userInfo,
        }
      : {
          token: false,
          user: false,
          role: 'anon',
        },
});
const isTeam = user =>
  user && user.id && user.role === 'team' ? true : false;
const isAdmin = user =>
  user && user.id && user.role === 'admin' ? true : false;

function AppMain() {
  return (
    <Provider store={store}>
      <BrowserRouter history={history} basename={'app'}>
        <Routes>
          <Route path="/" element={<App />}>
            <Route
              path="/"
              index
              element={
                isTeam(store.getState().auth.user) ? (
                  <Navigate to="/app_main_page" />
                ) : isAdmin(store.getState().auth.user) ? ( 
                  <Navigate to="/subscribers" />

                ): (
                  <Login />
                )
              }
            />

            {isTeam(store.getState().auth.user) ? (
              <>
                <Route path="app_main_page" element={<AppDashboardSettings />} />
                <Route path="users" element={<UsersMy />} />
                <Route path="purchase" element={<Purchase />} />
                <Route path="add-user" element={<UsersNew />} />
                <Route path="users/edit/:userId" element={<UsersEdit />} />
                <Route path="my-profile" element={<MyProfile />} />
                <Route path="logout" element={<Logout/>} />
                <Route path="safety-contacts" element={<SafetyContactsMy />} />
                <Route path="button_press_settings/:formId" element={<ButtonPressSettings />} />
                <Route path="form_settings/:formId" element={<FormSettings />} />
                <Route path="button_data/:btnId" element={<ButtonPressData />} />
                <Route path="form_data/:formId" element={<FormDataMy />} />
                
                
              </>
            ) : isAdmin(store.getState().auth.user) ? (
              <>
                <Route path="subscribers" element={<SubscribersAll />} />
                <Route path="configuration" element={<Configuration />} />
                <Route path="safetyContacts" element={<SafetyContacts />} />
                <Route path="logout" element={<Logout/>} />
                <Route path="plans" element={<Plans />} />
                <Route path="subscriber/users/:teamId" element={<Users />} />
                <Route path="subscriber/profile/:teamId" element={<TeamProfile />} />
                <Route path="subscriber/data/:teamId" element={<TeamData />} />
                <Route path="subscriber/button_data/:teamId/:btnId" element={<ButtonData />} />
                <Route path="subscriber/form_data/:teamId/:formId" element={<FormData />} />
                <Route path="new-plan" element={<PlanNew />} />
                <Route path="plan/edit/:planId" element={<PlanEdit />} />
                <Route path="new-subscriber" element={<SubscriberNew />}  />

                
              </>
            ) : (
              // <Route path="avbob-users" element={<Navigate to="/login" />} />
              <>

              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<Register />} />
              </>

              
            )}
                  <Route path="*" element={<NotFound />} /> 
          </Route>
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default AppMain;
