import React from 'react';
import {connect} from 'react-redux';
import Fetcher from '../../actions/Fetcher';
import {Link} from 'react-router-dom';
import Messages from '../../components/Messages';

import common from '../../components/common';
import DataTable from 'react-data-table-component';
import axios from 'axios';

class SubscribersAll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      page: 1,
      perPage: 20,
      data: [],
      loading: true,
      pagination: {},
    };
  }

  columns = [
    {
      name: 'Account Number',
      sortable: true,
      grow: 1,
      selector: row => row.id,
      sortField: 'id',
    },
    {
      sortable: true,
      name: 'Name',
      selector: row => row.first_name,
      sortField: 'first_name',
      cell: row => (row.first_name || '') + ' ' + (row.last_name || ''),
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortField: 'email',
      grow: 2,
      sortable: true,
      hide: 'sm',
    },
    {
      name: 'Account Name',
      selector: row => row.account_name,
      sortable: true,
      sortField: 'account_name',
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true,
      hide: 'sm',
      sortField: 'phone',
    },
    {
      name: 'Subscription Info',
      selector: row => row.is_paid,
      sortField: 'is_paid',
      sortable: true,
      // format: row => (row.is_paid ? 'Yes' : 'No'),
      format: row =>  row.plan === 'upgrade_by_admin' ? 'Manual Upgrade by Admin' : row.plan == 'paid' ? 'Paid ' + '(' + row.plan_obj + ')' : row.plan
    }, 
    {
      name: 'Max app user count',
      selector: row => row.max_users,
      sortField: 'max_users',
      sortable: true,
      }, 
    {
      name: 'Is Blocked',
      selector: row => row.status,
      sortField: 'status',
      sortable: true,
      format: row => parseInt(row.status) ? 
      <span className='badge badge-primary'>No</span> 
      : 
      <span className='badge badge-danger'>Yes</span> 
    }, 
    {
      allowOverflow: true,
      sortable: false,
      // selector: "actions",
      name: 'Actions',
      cell: u => (
        <div className="dropdown">
          <button
            className="btn btn-dark btn-xs dropdown-toggle btn-rounded"
            type="button"
            data-toggle="dropdown">
            Actions
            <span className="caret" />
          </button>
          <div className="dropdown-menu">
            <Link className="dropdown-item text-primary" to={'/subscriber/users/' + u.id}>
              View Users
            </Link>

            <Link className="dropdown-item text-primary" to={'/subscriber/profile/' + u.id}>
              View Profile
            </Link>
            <Link className="dropdown-item text-primary" to={'/subscriber/data/' + u.id}>
              View Data
            </Link>

            <Link
              className="dropdown-item text-danger confirm"
              to={'/subscribers'}
              onClick={()=>{
                if(this.state['update_' + u.id])return;

                if(window.confirm('Are you sure you want to '+(u.status ? "block" : "un-block" )+ ' the subscriber?'))
                {
                  this.setState({['update_' + u.id]: true})
                  this.updateItem(u.id, {
                    status : u.status ?0 : 1
                  });
                }
              }}>
              {this.state['update_'+u.id] ? 'please wait...': u.status ? 'Block Subscriber' : 'Unblock Subscriber'}
            </Link>

          
            {/* 
            <li>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  this.doAction('rates', u);
                }}
              >
                Modify Information
              </a>
              <a href="#"
            onClick={() => {
              this.updateItem(u.id, {
                is_banned: !u.is_banned
              });
            }}
            className={!u.is_banned ? 'text-danger' : 'text-primary'}
          >
            {this.state['update_' + u.id] ? (
              <i className="fa fa-spinner fa-spin" />
            ) : (
              false
            )}{' '}
            {u.is_banned ? 'Unblock Access' : 'Block Access'}
          </a> */}

            {/* </li> */}
          </div>
        </div>
      ),
    },
  ];

  updateItem(id, data) {
    Fetcher.post(common.bs + '/update/team?token='+this.props.token, {
      id: id,
      data: data,
      token: this.props.token,
    })
      .then(resp => {
        this.setState({
          ['update_' + id]: false,
        });
        if (resp.ok) {
          this.props.dispatch({
            type: 'SUCCESS',
            messages: [{ok: true, msg: 'Update Successfully.'}],
          });
          setTimeout(()=>{
            this.props.dispatch({
              type: 'CLEAR_MESSAGES',
            });
          }, 2000);

          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({type: 'FAILURE', messages: [resp]});
        }
      })
      .catch(err => {
        this.setState({
          ['update_' + id]: false,
        });
        console.log(err);
        const msg = 'Failed to perform Action';
        this.props.dispatch({
          type: 'FAILURE',
          messages: [{msg: msg}],
        });
      });
  }

  async componentDidMount() {
    this.handlePageChange(this.state.page);
  }

  handleSort = async (a, b) => {
    this.setState(
      {
        sort: a.sortField,
        sort_order: b,
      },
      () => {
        this.handlePageChange(1);
      },
    );
  };

  handleOnChangeRowsPerPage = async page => {
    this.setState(
      {
        perPage: page,
      },
      () => {
        this.handlePageChange(1);
      },
    );
  };

  handlePageChange = async page => {
    const {perPage} = this.state;

    this.setState({loading: true});
    let sort = '';
    if (this.state.sort) {
      sort = '&sort=' + this.state.sort + '&order=' + this.state.sort_order;
    }
    const response = await axios.get(
      `${common.bs}/listPaged/team?page=${page}&per_page=${perPage}&delay=1${sort}&token=${this.props.token}`,
    );
    this.setState({
      items: response.data.items,
      page: page,
      totalRows:
        response.data.pagination.pageCount * response.data.pagination.pageSize,
      loading: false,
    });
  };

  render() {
    const {totalRows, loading} = this.state;
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <Messages messages={this.props.messages} />
            <DataTable
              title={'Subscribers'}
              columns={this.columns}
              data={this.state.items}
              paginationRowsPerPageOptions={[ 10, 20, 25, 50, 75, 100, 200, 500]}
              paginationPerPage={this.state.perPage}
              highlightOnHover
              fixedHeader
              noContextMenu
              fixedHeaderScrollHeight={'70vh'}
              pagination
              progressPending={loading}
              persistTableHead
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={this.handlePageChange}
              onChangeRowsPerPage={this.handleOnChangeRowsPerPage}
              onSort={this.handleSort}
              sortServer
              striped
              // dense
              actions={[
                <Link
                  className="btn btn-dark btn-rounded"
                  to="/new-subscriber"
                  key="new-subscriber">
                  <i className="fa-solid fa-plus"></i> New Subscriber
                </Link>,
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(SubscribersAll);
