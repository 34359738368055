import React from 'react';
// import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import { useParams } from "react-router-dom";


function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}
class TeamData extends React.Component {
  state = {
    lst: []
  }
  componentDidMount(){
    
    const teamId = this.props.params.teamId;
    
  this.setState({
    teamId: teamId,
    lst:  [
    {id: 1, key: '/subscriber/button_data/'+teamId+'/1', value: 'OSHA on Site'},
    {id: 2, key: '/subscriber/button_data/'+teamId+'/2', value: 'Report an Incident/Accident'},
    {id: 3, key: '/subscriber/button_data/'+teamId+'/4', value: 'Report a Safety Complaint'},
    {id: 4, key: '/subscriber/button_data/'+teamId+'/5', value: 'Lone Worker Emergency'},
    {id: 5, key: '/subscriber/form_data/'+teamId+'/1', value: 'Ask a Safety Question'},
    {id: 5, key: '/subscriber/form_data/'+teamId+'/3', value: 'Need a Safety Product'},
    {
      id: 7,
      key: '/subscriber/form_data/'+teamId+'/2',
      value: 'Request Safety Consultation',
    },

 


  ]});
}

  render() {
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12 grid-margin stretch-card p-4">
            <div className="card">
              <div className="card-body">
                <h4 className="card-title" > <span className="float-right align-right pull-right" style={{marginTop:-10}}>
                  <Link
                    className="btn btn-dark btn-rounded"
                    to="/subscribers"
                    key="subscribers">
                    <i className="fa-solid fa-chevron-left"></i> Go Back
                  </Link> 
                  </span>
                  Subscriber Data - #{this.state.teamId}</h4>
                  
                  <hr />
                  <br />
                  
                  
                  <table className="table table-stripped table-hover nodt">
              
                    <tbody>
                      {this.state.lst.map((item, i) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              <Link to={item.key} className="text-primary">{item.value}</Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  </div>
                </div>
        </div>
      </div>
      </div>
    );
  }
}
 

export default withParams(TeamData);
