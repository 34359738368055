import React from 'react';
import {connect} from 'react-redux';
import axios from 'axios';
import Messages from '../../components/Messages';
import common from '../../components/common';

class PlanNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      plan_name: '',
      plan_cost: '',
      plan_term: '',
      plan_stripe_id: '',
      max_users: '',
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }

  async createPlan(e) {
    e.preventDefault();

    let {plan_name, plan_cost, plan_term, plan_stripe_id, max_users} =
      this.state;
    let obj = {
      plan_name,
      plan_cost,
      plan_term,
      plan_stripe_id,
      max_users,
    };
    let send_data = {
      data: obj,
    };

    let url = `${common.bs}/create/plan?token=${this.props.token}`;

    const response = await axios.post(url, send_data);
    // console.log(response)
    let data = response.data;
    if (data.ok) {
      this.setState({
        plan_name: '',
        plan_cost: '',
        plan_term: '',
        plan_stripe_id: '',
        max_users: '',
      });
      this.props.dispatch({
        type: 'SUCCESS',
        messages: [{msg: 'Plan Created successfully.'}],
      });

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    } else {
      this.props.dispatch({
        type: 'FAILURE',
        messages: [{msg: data.msg || 'Failed to create Plan'}],
      });
    }
  }

  render() {
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-8 bg-white p-3">
            <h2>{'New Plan'}</h2>

            <Messages messages={this.props.messages} />

            <hr />
            <br />

            <form onSubmit={this.createPlan.bind(this)}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Plan Name</label>
                    <input
                      value={this.state.plan_name}
                      type="text"
                      required
                      name="plan_name"
                      id="plan_name"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label>Plan Cost</label>
                    <input
                      value={this.state.plan_cost}
                      type="number"
                      step="0.01"
                      min="0.1"
                      required
                      name="plan_cost"
                      id="plan_cost"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label>Plan Term</label>
                    <input
                      value={this.state.plan_term}
                      type="text"
                      required
                      name="plan_term"
                      id="plan_term"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label>Plan Stripe ID</label>
                    <input
                      value={this.state.plan_stripe_id}
                      type="text"
                      required
                      name="plan_stripe_id"
                      id="plan_stripe_id"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>

                  <div className="form-group">
                    <label>Plan Max users</label>
                    <input
                      value={this.state.max_users}
                      type="number"
                      min="1"
                      required
                      name="max_users"
                      id="max_users"
                      onChange={this.handleChange.bind(this)}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <p>
                <input
                  type="submit"
                  className="btn btn-dark btn-rounded"
                  value="Create Plan"
                />
              </p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(PlanNew);
