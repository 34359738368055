import {Component} from 'react';
import {connect} from 'react-redux';
import common from '../../components/common';
import Messages from '../../components/Messages';
import axios from 'axios';
import Fetcher from '../../actions/Fetcher';

class AppDashboardSettings extends Component {
  state = {
    btn_1_mode: '',
    btn_2_mode: '',
    btn_3_mode: '',
    btn_4_mode: '',
    btn_5_mode: '',
    btn_6_mode: '',
    btn_7_mode: '',
    btn_8_mode: '',
    btn_3_icon_color: '',
    btn_4_icon_color: '',
    btn_5_icon_color: '',
    btn_7_icon_color: '',
    btn_8_icon_color: '',
    home_page_top_text: '',
  };
  btns = [
    'Help - OSHA on Site',
    'Report an Incident/Accident',
    'Ask a Safety Question',
    'Report a Safety Complaint',
    'Lone Worker Emergency',
    'Need a Safety Product',
    'Request Safety Consultation',
    'Contact Safety Consultants USA Directly',
  ];

  vals = [
    'btn_1_mode',
    'btn_2_mode',
    'btn_3_mode',
    'btn_4_mode',
    'btn_5_mode',
    'btn_6_mode',
    'btn_7_mode',
    'btn_8_mode',
    'btn_3_icon_color',
    'btn_4_icon_color',
    'btn_5_icon_color',
    'btn_7_icon_color',
    'btn_8_icon_color',
    'home_page_top_text',
  ];

  handleChange(e) {
    this.setState({
      [e.target.id]: e.target.value,
    });
  }
  async componentDidMount() {
     
    this.fetchData();
  }
  async fetchData() {
    let vals = this.vals.join('|');
      let url = `${common.teambs}/listPaged/settings?my=yes&filterin_setting_key=${vals}&token=${this.props.token}`;

      const response = await axios.get(url);

      let data = response.data;
      if (data.items && data.items.length) {
        this.processInput(data.items);
      }
  }

  processInput(data) {
    let values = {};
    data.map((item, i) => {
      values[item.setting_key] = item.setting_value;
      values['id_for_' + item.setting_key] = item.id;
      return item;
    });
    // console.log(values);
    this.setState(values);
  }

  doneSaving(ch, s, f) {
    let msg = 'Updated Successfully.';
    if (ch) {
      msg = '' + s + ' values updated successfully. ' + f + ' values failed';
    }
    this.props.dispatch({
      type: 'SUCCESS',
      messages: [{ok: true, msg: msg}],
    });
    setTimeout(() => {
      this.props.dispatch({
        type: 'CLEAR_MESSAGES',
      });
    }, 2000);
  }

  async saveItem(id, key, value, cb) {
    const response = await Fetcher.post(
      common.teambs + '/update/settings?token=' + this.props.token,
      {
        id: id,
        data: {
          setting_value: value,
        },
      },
    );
    // return
    cb(response.ok ? 'yes' : 'no');
  }

  async doSubmit(e) {
    e.preventDefault();

    let needs_saving = this.vals.length;
    let total_success = 0;
    let total_fail = 0;
    this.vals.map((item, i) => {
      this.saveItem(
        this.state['id_for_' + item],
        item,
        this.state[item],
        vl => {
          // console.log(vl);

          if (vl === 'yes') {
            total_success = total_success + 1;
          } else {
            total_fail = total_fail + 1;
          }
          needs_saving = needs_saving - 1;

          if (needs_saving < 1) {
            if (total_fail > 0) {
              this.doneSaving(true, total_success, total_fail);
            } else {
              this.doneSaving(false);
            }
          }
        },
      );
      return item;
    });
  }
  render() {
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="card-description">App Dashboard Settings</h4>
              </div>
            </div>
            <Messages messages={this.props.messages} />
      
            <div className="row">
              <div className="col-md-6 grid-margin stretch-card">
                <form
                  onSubmit={this.doSubmit.bind(this)}
                  className="forms-sample"
                  method="post"
                  // enctype="multipart/form-data"
                  style={{width: '100%'}}>
                  <div className="card">
                    <div className="card-body">
                      <div className="card-description">
                        <div className="form-group">
                          <label htmlFor="home_page_top_text">
                            Main Screen Top Text
                          </label>
                          <input
                            className="form-control"
                            value={this.state.home_page_top_text}
                            onChange={this.handleChange.bind(this)}
                            name="home_page_top_text"
                            id="home_page_top_text"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  {this.btns.map((item, m) => {
                    let i = m + 1;
                    return (
                      <div className="card" key={i}>
                        <div className="card-body">
                          <h4 className="card-title">{this.btns[m]}</h4>
                          <div className="card-description">
                            <div className="form-group">
                              <label htmlFor={'btn_' + i + '_mode'}>
                                Button Mode:
                              </label>
                              <select
                                className="form-control"
                                value={this.state['btn_' + i + '_mode']}
                                id={'btn_' + i + '_mode'}
                                required
                                onChange={this.handleChange.bind(this)}
                                name={'btn_' + i + '_mode'}>
                                <option value="">Select</option>
                                <option value="yes">Enabled</option>
                                <option value="no">Disabled</option>
                              </select>
                            </div>
                            {i !== 1 && i !== 2 && i !== 6 ? (
                              <div className="form-group">
                                <label htmlFor="btn_'+i+'_icon_color">
                                  Icon Color:
                                </label>
                                <input
                                  autoComplete="off"
                                  type="color"
                                  // style={{padding: 0}}
                                  onChange={this.handleChange.bind(this)}
                                  className="form-control colorp"
                                  style={{maxWidth:30, padding:0, width:30, height:30}}
                                  value={this.state['btn_' + i + '_icon_color']}
                                  id={'btn_' + i + '_icon_color'}
                                  name={'btn_' + i + '_icon_color'}
                                />
                              </div>
                            ) : (
                              false
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <button
                    type="submit"
                    className="btn btn-dark btn-rounded mr-2"
                    name="submit_to_status_email">
                    Save Data
                  </button>
                  <button className="btn btn-secondary btn-rounded" type="button" onClick={this.fetchData.bind(this)}>
                    Cancel
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(AppDashboardSettings);
