import React from 'react';
import {connect} from 'react-redux';
import Fetcher from '../../actions/Fetcher';

import Messages from '../../components/Messages'; 

import common from '../../components/common';
import axios from 'axios';


const labels = {
  bermac_email_address_for_vendor_submissions:'Safety Consultants USA Email Address(es) For User Submissions',
  bermac_phone_number_for_vendor_submissions:  'Safety Consultants USA Phone Number(s) For User Submissions',
  stripe_secret_key: 'Stripe Secret Key',
  stripe_publishable_key  : 'Stripe Publishable Key'
};

class Configuration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is_loaded: false,
      loading: true,
    };
  }

  
  save(row, i, keyToDelete)
  {
    this.setState({['saving_'+row.config_key] : true})
    this.updateItem(row.id, {
      config_value : this.state[row.config_key]
    }, 'saving_'+row.config_key, keyToDelete);
  }

  updateItem(id, data, statekey, key_to_delete) {
    Fetcher.post(common.bs + '/update/config?token='+this.props.token, {
      id: id,
      data: data,
      token: this.props.token,
    })
      .then(resp => {
        this.setState({
          [statekey]: false,
          [key_to_delete]:false
        });
        if (resp.ok) {
          this.props.dispatch({
            type: 'SUCCESS',
            messages: [{ok: true, msg: 'Update Successfully.'}],
          });

          this.handlePageChange(this.state.page);
        } else {
          this.props.dispatch({type: 'FAILURE', messages: [resp]});
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({[statekey]:false})
        const msg = 'Failed to perform Action';
        this.props.dispatch({
          type: 'FAILURE',
          messages: [{msg: msg}],
        });
      });
  }

  async componentDidMount() {
    this.handlePageChange(this.state.page);
  }
 

  handlePageChange = async page => {
    this.setState({loading: true});
    const response = await axios.get(
      `${common.bs}/listPaged/config?page=1&per_page=100&token=${this.props.token}`,
    );
    let items = response.data.items;
    items = items.filter((item)=>item.config_key =='contact_person'?false:true);
    this.setState({
      items: items,
      page: page,
      loading: false,
    });
  };

  render() {
    return (
      <div className="container-fluid dashboard-content">
        <div className="row">
          <div className="col-md-12" style={{background:'#fff', paddingBottom:20, paddingTop:20}}>
            <Messages messages={this.props.messages} />
<h4>Configuration</h4>
            <table className='table ' style={{width:'100%'}}>
              <thead>
                <tr>
                  <th>Configuration Name</th>
                  <th>Value</th>
                  <th></th>
                </tr>
                
              </thead>
              <tbody>
                {
                  this.state.items ? this.state.items.map((row, i)=>{
                    return (
                      <tr key={row.config_key}>
                        <td>{labels[row.config_key]}</td>
                        <td>
                        <input type="text" className="form-control" aria-describedby="button-addon2" value={this.state[row.config_key] || row.config_value} onChange={e=>{
        this.setState({[row.config_key] : e.target.value});
      }} />
        
                        </td>
                        <td>
              
          <button disabled={!this.state[row.config_key]} className="btn btn-success btn-rounded" type="button" id="button-addon2" onClick={e=>{
            if(this.state['saving_'+row.config_key])return;
            this.save(row,i, row.config_key);
          }}>{this.state['saving_'+row.config_key] ? 'please wait...': 'Save'}</button>
                        </td>
                      </tr>
                    )
                  }):false
                }
              </tbody>
              </table>
            
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    messages: state.messages,
  };
};

export default connect(mapStateToProps)(Configuration);
